import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../services/settings.service';

@Component({
  selector: 'odin-odin-settings-page',
  templateUrl: './odin-settings-page.component.html',
  styleUrls: ['./odin-settings-page.component.scss'],
})
export class OdinSettingsPageComponent {
  get themeName(): string {
    return this.settingsService.GetNotNullSetting<string>('themeName', 'light').value;
  }
  set themeName(themeName: string) {
    this.settingsService.SetSetting('themeName', themeName);
  }

  get authToken(): string {
    return localStorage.getItem('token') || '';
  }

  constructor(private settingsService: SettingsService,
    private router: Router,
    @Inject('themes') private themes: { view: string, value: string }[]) {
  }

  public secretFetchToken(event: MouseEvent): void {
    if (event.altKey || event.shiftKey) {
      navigator.clipboard.writeText(this.authToken);
    }
  }

  public themeOptions(): { view: string, value: string }[] {
    return this.themes;
  }
}
