import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettlementDailyPageComponent } from './settlement-daily-page/settlement-daily-page.component';
import { SettlementListPageComponent } from './settlement-list-page/settlement-list-page.component';
import { TransactionsListPageComponent } from './transactions-list-page/transactions-list-page.component';
import { TransactionDetailPageComponent } from '../transaction-detail-page/transaction-detail-page.component';

export const routes: Routes = [
  { path: '', component: TransactionsListPageComponent },
  { path: 'detail/:traceId', component: TransactionDetailPageComponent },
  // MVP only !!!
  { path: '', component: SettlementListPageComponent },
  { path: 'settlements', component: SettlementListPageComponent },
  { path: 'settlements/:date', component: SettlementDailyPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionsLayoutRoutingModule { }
