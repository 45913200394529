import { EventEmitter, Injectable } from '@angular/core';
import { BUID } from '../models/BUID';
import { MerchantService } from './merchant.service';

type ChangeCallack = {
  name: string,
  callback: (buid: string) => void
}

@Injectable({ providedIn: 'root' })
export class MerchantChangeService {
  public merchantChangeEvent: EventEmitter<string> = new EventEmitter<string>();
  private changeListeners: ChangeCallack[] = [];

  constructor(private merchantService: MerchantService) {
  }

  public onChange(name: string, callback: (buid: string) => void): void {
    this.changeListeners.push({ name: name, callback: callback });
  }

  public setActiveMerchant(merchant: BUID): void {
    try {
      const mid = this.merchantService.getCurrentMerchant();
      if (mid !== null && merchant.id == mid.id) return;
      localStorage.setItem('current-merchant-ref', JSON.stringify(merchant));
      this.changeListeners.forEach((c: ChangeCallack) => {
        c.callback(merchant.id)
      });
      this.merchantChangeEvent.emit(merchant.id);
    } catch {
      // logging
    }
  }

  public getMerchantRefs(): BUID[] {
    try {
      const merchantRef = localStorage.getItem('merchant-ref');
      if (merchantRef == null) return [];
      const merchantRefs = JSON.parse(merchantRef) as BUID[];
      return merchantRefs;
    } catch {
      return [];
    }
  }
}
