import {
  AfterContentInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import {
  faBars,
  faCaretRight,
  faChevronDown,
  faChevronRight,
  faUserAstronaut,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { NavigationItem } from '../../Models/NavigationItem';
import { OdinNavigationService } from '../../Services/odin-navigation-service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment as env } from 'apps/odin-ui/src/environments/environment';
import { Observable } from 'rxjs';
import { CognitoService, UserInformation } from '@odin/odin-authentication';
import { StaticHelpers } from '@odin/odin-core';
import { BrandingHelper } from '../../Services/branding-helper';

@Component({
  selector: 'odin-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent implements OnInit, AfterContentInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  public bhelper = BrandingHelper;

  public authenticatedView = false;
  public ddOpen = faChevronDown;
  public ddClosed = faChevronRight;
  public ddOption = faCaretRight;

  public get userIcon(): IconDefinition {
    return faUserAstronaut;
  }
  public get menuIcon(): IconDefinition {
    return faBars;
  }
  public mobileSidebarMode: MatDrawerMode = 'over';
  public desktopSidebarMode: MatDrawerMode = 'side';
  public burgerMenuOpen = false;
  public sidebarMode: MatDrawerMode = this.desktopSidebarMode;
  public versionNumber: string =
    env.releaseVersion == '|RELEASE-VERSION|'
      ? 'Development'
      : env.releaseVersion;
  public user_information$!: Observable<UserInformation>;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    setTimeout(() => {
      const target = event.target as Window;
      this.setSidebarProperties(target.innerWidth);
    }, 0);
  }

  private setSidebarProperties(width: number) {
    if (width > 850) {
      this.sidebarMode = this.desktopSidebarMode;
      this.sidenav?.open();
      this.burgerMenuOpen = true;
    } else {
      this.sidenav?.close();
      this.sidebarMode = this.mobileSidebarMode;
    }
  }

  constructor(
    public navigationService: OdinNavigationService,
    private router: Router,
    private cognitoService: CognitoService,
  ) {
    this.cognitoService.loginChanged.subscribe((loggedIn: boolean) => {
      document.title = loggedIn ? 'Monek Portal' : 'Monek Portal Login';
      this.authenticatedView = loggedIn;
      if (!loggedIn && this.cognitoService.userLoggedIn())
        this.router.navigate(['/']);
    });
    this.authenticatedView = this.cognitoService.userLoggedIn();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.cognitoService.checkRefresh();
        if (this.authenticatedView && !this.cognitoService.isTokenValid()) {
          this.cognitoService.logout();
        }
      }
    });
  }

  ngOnInit(): void {
    this.navigationService.navigationRoutes;
    // this.user_information$ = this.cognitoService.getUserInfoObs();
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.setSidebarProperties(window.innerWidth);
    }, 0);
  }

  public navigate(navigationItem: NavigationItem): void {
    if (
      navigationItem.advanced !== undefined &&
      navigationItem.advanced.subRoutes !== undefined
    ) {
      navigationItem.advanced.opened = !navigationItem.advanced.opened;
      return;
    }

    if (
      navigationItem.advanced != null &&
      navigationItem.advanced.action != null
    )
      navigationItem.advanced.action();

    if (navigationItem.route != '')
      this.router.navigate([navigationItem.route]).then(() => {
        if (screen.width < 850) {
          this.sidenav.close();
        }
      });
  }
}
