import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, of, Subscription, withLatestFrom } from 'rxjs';
import { TransactionsFacade } from '../../../+state/transactions/transactions.facade';
import { CsvReportRequest } from '../../models';
import { ReportsDialogComponentModel } from './reports-dialog-component-model';
import * as moment from 'moment';

@Component({
  selector: 'odin-csv-reports-dialog',
  templateUrl: './csv-reports-dialog.component.html',
  styleUrls: ['./csv-reports-dialog.component.scss'],
})
export class CsvReportsDialogComponent implements OnInit, OnDestroy {
  public csvMinDate: Date = new Date();
  public csvMaxDate: Date = new Date();
  public searchExpression: string | undefined;
  public dateRangeError: string = '';
  public error$: Observable<string> = of('');
  public loading$: Observable<boolean> = of(true);
  public subscription: Subscription | null = null;
  public searchExpressionSubscription: Subscription | null = null;

  constructor(
    public dialogRef: MatDialogRef<CsvReportsDialogComponent>,
    private facade: TransactionsFacade,
    @Inject(MAT_DIALOG_DATA) public data: ReportsDialogComponentModel,
  ) {
    this.csvMinDate = data.csvMinDate;
    this.csvMaxDate = data.csvMaxDate;

    this.validateDates(this.csvMinDate, this.csvMaxDate);

    this.searchExpressionSubscription = this.facade.getParams$.subscribe(value => {
      this.searchExpression = value.query
    });
  }

  ngOnInit() {
    this.error$ = this.facade.getCsvReportsError$;
    this.loading$ = this.facade.getCsvReportsLoader$;
  }

  onDismiss() {
    this.dialogRef.close();
  }

  onConfirm() {
    const minimumDate = moment(this.csvMinDate)
      .add(-new Date(this.csvMinDate).getTimezoneOffset(), 'minutes')
      .toDate();
    const maximumDate = moment(this.csvMaxDate)
      .add(-new Date(this.csvMaxDate).getTimezoneOffset(), 'minutes')
      .add(1, 'day')
      .toDate();

    const payload: CsvReportRequest = {
      minDate: minimumDate,
      maxDate: maximumDate,
      searchExpression: this.searchExpression
    };

    this.facade.downloadCsvReport(payload);

    this.subscription = this.loading$
      .pipe(
        withLatestFrom(this.error$),
        map(([loading, error]) => {
          return [loading, error];
        }),
      )
      .subscribe(([loading, error]) => {
        // Keep the dialog box open is there is an error
        if (!loading && error === '') {
          this.dialogRef.close('download');
        }
      });
  }

  private validateDates(minDate: Date, maxDate: Date) {
    if (this.getDayDiff(new Date(minDate), new Date(maxDate)) > 31) {
      this.dateRangeError = 'Please select dates in range of 31 days.';
    } else {
      this.dateRangeError = '';
    }
  }

  private getDayDiff(startDate: Date, endDate: Date): number {
    const msInDay = 24 * 60 * 60 * 1000;

    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  }

  ngOnDestroy() {
    if (this.subscription !== undefined && this.subscription !== null)
      this.subscription.unsubscribe();
    
    if (this.searchExpressionSubscription !== undefined && this.searchExpressionSubscription !== null)
      this.searchExpressionSubscription.unsubscribe();
  }
}
